@import url("https://fonts.googleapis.com/css?family=Noto+Sans:100,300,400,500,700,900&display=swap");

html,
body {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Noto Sans", sans-serif;
}

svg text {
  font-family: "Noto Sans", sans-serif;
}

.tichtby {
  .practice-logo {
    width: 200px !important;
  }
}

$column-width: 1024px;
$half-column: 500px;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 5px 0 5px 0;
    font-size: 24px;
    font-weight: 600;
  }

  h2 {
    margin: 5px 0 5px 0;
    font-size: 18px;
    font-weight: 600;
  }

  h3 {
    margin: 5px 0 5px 0;
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }

  h4 {
    margin: 10px 0 0px 0;
    font-size: 12px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    line-height: 26px;
  }

  .page {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 300px;
      position: relative;
      top: -5px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: $column-width;
      height: 110px;

      .menu {
        font-size: 14px;

        .items {
          .item {
            float: right;
            color: black;

            margin: 10px 0 10px 35px;
          }
        }
      }

      .stack-menu {
        display: none;
      }
    }

    .footer {
      .info {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }

    .banner {
      width: 100%;
      height: 400px;
      max-height: 50vw;

      background-image: url("./images/landschap.jpg");
      background-position-y: center;
      background-size: cover;

      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 50px;

      .slogan {
        width: $column-width;
      }
    }

    .block-background {
      background-color: #f4f4f4;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .block {
      width: $column-width;
      display: flex;
      justify-content: space-between;

      .column {
        width: $half-column;
      }

      .text-column {
        margin: 30px 0;
      }

      .image-column {
        img {
          max-width: 100%;
        }
      }

      .center-column {
        display: flex;
        align-items: center;
      }
    }

    .website {
      font-size: 14px;
      text-decoration: none;
      color: darkblue;
    }

    .city {
      font-weight: 700;
    }

    .email {
      text-decoration: none;
      color: darkblue;
    }

    .map {
      width: $half-column;
      height: 465px;
    }

    .search {
      width: 100%;
      height: 20px;
      padding: 20px;
      border: 2px solid #eee;
      border-radius: 5px;
    }

    .search_pin_color {
      width: 20px;
      float: right;
      position: relative;
      top: -10px;
    }

    .practice {
      margin-bottom: 30px;
      background: #eee;
      padding: 20px;

      h3 {
        margin: 15px 0 10px 0;
      }
    }

    .practice-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    .practice-logo {
      width: 250px;
    }

    .practice-photo {
      height: 150px;
    }

    .icon {
      width: 14px;
      margin-right: 5px;
      display: inline-block;
    }
  }
}

@media (min-width: $column-width + 61px) {

  .show-mobile { display: none !important; }

}

@media (max-width: $column-width + 60px) {

  .show-desktop { display: none !important; } 

  .container {
    width: 100%;

    .page {
      width: 100%;

      .header {
        width: 100%;

        .logo {
          margin-left: 20px;
        }

        .menu {
          display: none;
        }

        .stack-menu {
          display: unset;
          margin-left: 20px;
          margin-right: 30px;
          cursor: pointer;

          .burger {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          .items {
            position: absolute;
            right: 00px;
            top: 70px;
            text-align: right;
            background-color: white;
            padding: 20px 30px;

            a {
              text-decoration: none;

              .item {
                margin: 10px 0;
                color: black;
              }
  
            }
          }
        }
      }

      .banner {
        width: 100%;

        .slogan {
          width: calc(100% - 60px);
        }
      }

      .block {
        width: 100%;
        flex-direction: column;
        align-items: center;
    
        .column {
          width: calc(100% - 60px);
        }

        .image-column {
          text-align: center;

          img {
            width: 400px;
            max-width: 100%;
            padding: auto;
          }
        }

      }

      .map {
        width: 100%
      }

    }

  }

}

@media (max-width: 600px) {

  .container {

    h1 { font-size: 20px; }  
    h2 { font-size: 14px; }  
    h3 { font-size: 12px; }  
    h4 { font-size: 10px; } 

    p {
      font-size: 12px;
      line-height: 20px;
    }

    .page {
      .banner {
        font-size: 7.5vw;
        background-image: url("./images/landschap-small.jpg");
      }
    }
  }

}

@media (max-width: 525px) {

  .container .page {

    .logo {
      max-width: 100%;
    }

    .practice-logo {
      max-width: 100%;
      height: 50px;
      object-fit: contain;
    }

    .practice-header {
      flex-direction: column;
  
      .practice-profile {
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }
  }

}